<template>
  <div class="container">
    <div class="login_box">
      <!-- <div class="wel_come">
        欢迎登录
      </div>
      <p class="line"></p>
      <div class="lg_title">武汉市公义医疗器械有限公司管理系统</div>
      <div class="lg_con">科技智能一体化服务平台</div> -->
      <div class="lg_form">
        <h2 class="login_title">用户登录</h2>
        <p class="title">WELCOME TO THE SYSTEM</p>
        <el-form
          label-position="top"
          label-width="80px"
          :model="formModel"
          ref="loginForm"
          :rules="rules"
        >
          <el-form-item prop="user_name">
            <el-input
              v-model="formModel.username"
              placeholder="请输入系统登录账号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="formModel.password"
              show-password
              placeholder="请输入系统登录密码"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item prop="verify">
            <el-input
              v-model="formModel.verify"
              placeholder="请输入图形验证码"
            ></el-input>
            <div class="code" @click="getGraphical">
              <img :src="imgage" />
            </div>
          </el-form-item> -->
          <el-form-item>
            <el-button :loading="loading" @click="loginHandle('loginForm')"
              >登录</el-button
            >
            <!-- <el-button :loading="loading" @click="$router.push('/')"
              >登录</el-button
            > -->
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { httpGet, httpPost } from "@/apis/api";
export default {
  data() {
    return {
      formModel: {
        username: "",
        password: "",
      },
      imgage: "",
      uniqid: "",
      loading: false,
      rules: {
        username: [{ required: true, message: "请输入用户名" }],
        password: [{ required: true, message: "请输入密码" }],
        verify: [{ required: true, message: "请输入验证码" }],
      },
    };
  },
  created() {},
  mounted() {
    var _this = this;
    document.onkeydown = function(e) {
      if (e.keyCode == 13) {
        _this.loginHandle("loginForm");
      }
    };
  },
  methods: {
    loginHandle(formName) {
      let obj = this.formModel;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          httpPost("/backend/Login/index", obj)
            .then((res) => {
              if (res.status == 200) {
                this.$message.success(res.message);
                this.$store.commit("SET_TOKEN", res.data.token);
                this.$store.commit("GET_USER", res.data);
                window.sessionStorage.setItem("token", res.data.token);
                this.get_urls(res.data.department_id,res.data.is_admin);
              } else {
                this.$message.error(res.message);
                this.loading = false;
              }
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        }
      });
    },
    get_urls(id){
        httpGet("/backend/AdminMenu/index", {department_id:id})
          .then((res) => {
            if (res.status == 200) {
              this.$store.commit("SET_MENU", res.data.menu_list || []);
              this.$store.commit("SET_MENU_TREE", res.data.menu_tree || []);
              setTimeout(() => {
                this.loading = false;
                if(res.data.menu_tree.length>0){
                    this.$router.push(res.data.menu_tree[0].url);
                }else{
                    this.$message.error('您没有任何访问权限，请联系管理员');
                    // this.$router.push("/");
                }
              }, 1500);
            } else {
              this.$message.error(res.message);
              this.loading = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background: url("../assets/bg.png") no-repeat center center;
  .login_box {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 350px;
    border-radius: 20px;
    // background: url("../assets/lg_bg.png") no-repeat center center;
  }
  .login_box {
    padding: 80px 50px;
    color: #fff;
    letter-spacing: 5px;
    .wel_come {
      font-size: 20px;
    }
    .line {
      width: 50px;
      height: 1px;
      background-color: #fff;
      margin: 20px 0 30px;
    }
    .lg_title {
      font-size: 32px;
      width: 440px;
    }
    .lg_con {
      font-size: 16px;
      margin-top: 20px;
    }
    .lg_form {
      position: absolute;
      right: 0px;
      top: -45px;
      width: 21.875rem;
      height: 28.125rem;
      border-radius: 20px;
      background-color: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 40px;
      .login_title {
        font-size: 30px;
        color: #409eff;
      }
      .title {
        color: #ccc;
        font-size: 14px;
        letter-spacing: 1px;
        margin: 20px 0;
      }
      &:deep .el-input__inner {
        border-radius: 20px;
      }
      &:deep .el-button {
        color: #fff;
        width: 300px;
        margin-top: 20px;
        border-radius: 20px;
        background-color: #409eff;
      }
      &:deep .el-form-item__error {
        left: 12px;
      }
      .code {
        position: absolute;
        top: 0px;
        right: 18px;
        height: 39px;
        background-color: #f4f5f8;
        img {
          height: 100%;
        }
      }
    }
  }
}
</style>
